@if(!isLoading){
    <p-table #dt [value]="value" [columns]="cols" [rows]="rows" [paginator]="paginator"
        [rowsPerPageOptions]="rowsPerPageOptions" [selectionMode]="selectionMode" [(selection)]="selection"
        [globalFilterFields]="globalFilterFields" [rowHover]="true" [dataKey]="dataKey" [autoLayout]="autoLayout"
        [resizableColumns]="resizableColumns" [styleClass]="styleClass"
        [currentPageReportTemplate]="currentPageReportTemplate" [showCurrentPageReport]="showCurrentPageReport">

        <ng-template pTemplate="caption">
            <div class="flex flex-row flex-md-row md:justify-content-between table-header">
                <button pButton label="Reset" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt)"
                    style="width: 84px;"></button>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input #searchInput pInputText type="text"
                        (input)="dt.filterGlobal((searchInput.value || ''), 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th [ngClass]="[getColumnClass(col)]" *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}} <p-sortIcon
                        field="{{col.field}}"></p-sortIcon>
                    <p-columnFilter [type]="col.filterType" [field]="col.field" display="menu"></p-columnFilter>
                </th>
                <th class="text-center" *ngIf="actions && actions.length">Actions</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
            <tr [ngStyle]="rowStyle(rowData)">
                @for (col of cols; track $index) {
                    <td [ngStyle]="{'color': col.field === 'status' && rowData[col.field] === 'DELETED' ? 'red' : 'inherit'}" class="text-center"
                        [ngClass]="[getColumnClass(col)]">
                        <ng-container [ngSwitch]="col.type">
                            <ng-container *ngSwitchDefault>
                                <span class="p-column-title"><b>{{col.header}}</b></span>
                                {{rowData?.[col.field]}}
                            </ng-container>

                            <ng-container *ngSwitchCase="'image'">
                                <p-image [src]="rowData[col.field]" width="75px" height="75px" class="shadow-4"
                                    [preview]="true"></p-image>
                            </ng-container>

                            <ng-container *ngSwitchCase="'date'">
                                <span class="p-column-title"><b>{{col.header}}</b></span>
                                {{ rowData?.[col.field] | date }}
                            </ng-container>

                            <ng-container *ngSwitchCase="'dateTime'">
                                <span class="p-column-title"><b>{{col.header}}</b></span>
                                {{ rowData?.[col.field] | date: "MMM d, y, h:mm:ss a" }}
                            </ng-container>

                            <ng-container *ngSwitchCase="'boolean'">
                                <span class="p-column-title"><b>{{col.header}}</b></span>
                                <span>{{ rowData[col.field] ? '✅' : '❌' }}</span>
                            </ng-container>

                            <ng-container *ngSwitchCase="'number'">
                                <span class="p-column-title"><b>{{col.header}}</b></span>
                                {{ rowData?.[col.field] | number: '1.2-2' }}
                            </ng-container>

                            <ng-container *ngSwitchCase="'booleanTerms'">
                                <span class="p-column-title"><b>{{col.header}}</b></span>
                                <span>{{ rowData[col.field] ? 'Approved' : 'Rejected' }}</span>
                            </ng-container>
                        </ng-container>
                    </td>
                }
                @if (actions && actions.length) {
                    <td *ngIf="actions && actions.length" style="text-align: center !important;">
                        @for (action of actions; track $index) {
                            <button pButton pRipple [icon]="action.icon" [ngClass]="getButtonClass(action)"
                                class="p-button-rounded p-button mr-2 p-button-raised" [pTooltip]="action.tooltip"
                                tooltipPosition="top" (click)="triggerAction(action.name, rowData)"
                                style="width: 2rem; height: 2rem;"></button>
                            }
                    </td>
                }
            </tr>
        </ng-template>

        <ng-template pTemplate="summary">
            <div class="p-d-flex p-ai-center p-jc-between">
                In total there are {{ value ? value.length : 0 }} {{entries}}.
            </div>
        </ng-template>
    </p-table>
}@else {
    <p-table [value]="skeletonValue" [columns]="cols" [rows]="10" [paginator]="true"
        [rowsPerPageOptions]="[10, 20, 30, 40, 50, 100, { showAll: 'All' }]" autoLayout="true" resizableColumns="true"
        styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
        entries="Academic Session(s)">
        <ng-template pTemplate="caption">
            <div class="flex flex-row flex-md-row md:justify-content-between table-header">
                <button pButton label="Reset" class="p-button-outlined" icon="pi pi-filter-slash"
                    style="width: 84px;"></button>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input #searchInput pInputText type="text" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                @for(col of cols; track $index) {
                    <th>{{ col.header }} <p-sortIcon field="{{col.field}}"></p-sortIcon>
                        <p-columnFilter [type]="col.filterType" [field]="col.field" display="menu"></p-columnFilter>
                    </th>
                }
                @if(actions.length){
                    <th>Actions</th>
                }
            </tr>
        </ng-template>
        <ng-template pTemplate="body">
            @for(item of skeletonValue; track $index) {
                <tr>
                    @for(col of cols; track $index) {
                        <td><p-skeleton /></td>
                    }
                    @if (actions && actions.length) {
                        <td *ngIf="actions && actions.length">
                            <p-skeleton />
                        </td>
                    }
                </tr>
            }
        </ng-template>
    </p-table>
}