import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './shared/guard/auth-guard.guard';
import { Roles } from './shared/models/commons/user-roles';
import { NotFoundComponent } from './global/components/not-found/not-found.component';

const routes: Routes = [
    { path: '', loadChildren: () => import('./idp/idp.module').then(m => m.IdpModule) },
    { path: 'login', loadChildren: () => import('./idp/idp.module').then(m => m.IdpModule) },
    {
        path: 'home', component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            {
                path: 'cloudbytes',
                loadChildren: () => import('./cloud-bytes/cloud-bytes/cloud-bytes.module').then(m => m.CloudBytesModule),
                data: {
                    breadcrumb: 'CloudBytes',
                    userRoles: [Roles.MASTERS, Roles.MASTERSADMIN, Roles.MASTERSSUPERADMIN, Roles.ITSUPPORTS, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'finpro',
                loadChildren: () => import('./finance-Pro/finance-pro.module').then(m => m.FinanceProModule),
                data: {
                    breadcrumb: 'FinPro',
                    userRoles: [Roles.MASTERS, Roles.MASTERSADMIN, Roles.MASTERSSUPERADMIN, Roles.ITSUPPORTS, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'smallbizgurus',
                loadChildren: () => import('./smallbiz-gurus/smallbiz-gurus/smallbiz-gurus.module').then(m => m.SmallbizGurusModule),
                data: {
                    breadcrumb: 'SmallBizGurus',
                    userRoles: [Roles.HR, Roles.HRAdmin, Roles.HRReadWriteAccess, Roles.ITSUPPORTS, Roles.HRSuperAdmin, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'bigleads',
                loadChildren: () => import('./big-leads/big-leads/big-leads.module').then(m => m.BigLeadsModule),
                data: {
                    breadcrumb: 'BigLeads',
                    userRoles: [Roles.LEADS, Roles.LEADSADMIN, Roles.FACULTY, Roles.HR, Roles.ACADEMICS, Roles.ACADEMICSADMIN, Roles.ITSUPPORTS, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'mindspark',
                loadChildren: () => import('./mind-spark/mind-spark/mind-spark.module').then(m => m.MindSparkModule),
                data: {
                    breadcrumb: 'MindSpark',
                    userRoles: [Roles.ACADEMICS, Roles.ACADEMICSADMIN, Roles.FACULTY, Roles.ITSUPPORTS, Roles.MASTERS, Roles.MASTERSADMIN, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'digitalfingers', 
                loadChildren: () => import('./digital-fingers/digital-fingers/digital-fingers.module').then(m => m.DigitalFingersModule), 
                data: {
                    breadcrumb: 'DigitalFingers',
                    userRoles: [Roles.ITSUPPORTS, Roles.ITSUPPORTSSUPERADMIN, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'knowledgestand', 
                loadChildren: () => import('./knowledge-stand/knowledge-stand/knowledge-stand.module').then(m => m.KnowledgeStandModule), 
                data: {
                    breadcrumb: 'KnowledgeStand',
                    userRoles: [Roles.EXAMINATIONS, Roles.EXAMINATIONSADMIN, Roles.FACULTY, Roles.ITSUPPORTS, Roles.ITSUPPORTSSUPERADMIN, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'executiveedge',
                loadChildren: () => import('./executive-edge/executive-edge/executive-edge.module').then(m => m.ExecutiveEdgeModule),
                data: {
                    breadcrumb: 'ExecutiveEdge',
                    userRoles: [Roles.ITSUPPORTS, Roles.ITSUPPORTSSUPERADMIN, Roles.SUPERADMIN, Roles.ADMINISTRATION],
                }
            },
            {
                path: 'timeclockplus', 
                loadChildren: () => import('./time-clock-plus/time-clock-plus/time-clock-plus.module').then(mod => mod.TimeClockPlusModule), 
                data: {
                    breadcrumb: 'TimeClock Plus',
                    userRoles: [Roles.ACADEMICS, Roles.EXAMINATIONS, Roles.FACULTY, Roles.ACCOUNTS, Roles.HR, Roles.DEVELOPERS, Roles.ITSUPPORTS, Roles.LEADS, Roles.MASTERS, Roles.SUPERADMIN, Roles.ADMINISTRATION]
                },
            },
            {
                path: 'virtuallearn', 
                loadChildren: () => import('./virtual-learn/virtual-learn/virtual-learn.module').then(mod => mod.VirtualLearnModule), 
                data: {
                    breadcrumb: 'Virtual Learn',
                    userRoles: [Roles.VIRTUALLEARN, Roles.VIRTUALLEARNSUPERADMIN,Roles.VIRTUALLEARNADMIN, Roles.ACADEMICS, Roles.EXAMINATIONS, Roles.FACULTY, Roles.ACCOUNTS, Roles.HR, Roles.DEVELOPERS, Roles.ITSUPPORTS, Roles.LEADS, Roles.MASTERS, Roles.SUPERADMIN, Roles.ADMINISTRATION]
                },
            },
            {
                path: 'students', 
                loadChildren: () => import('./students/students.module').then(mod => mod.StudentsModule), 
                data: {
                    breadcrumb: 'Students',
                    userRoles: [Roles.STUDENT]
                }
            },
            {
                path: 'developers',
                loadChildren: () => import('./developers/developers.module').then(mod => mod.DevelopersModule),
                data: {
                    breadcrumb: 'Developers',
                    userRoles: [Roles.DEVELOPERS]
                }
            },
            { 
                path: 'settings', loadChildren: () => import('./settings/settings/settings.module').then(mod => mod.SettingsModule) 
            },
        ]
    },
    // { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
    // { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },

    { path: '**', component: NotFoundComponent, data: { title: 'NotFound' } },

    // { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    // { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
