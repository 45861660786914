import { Component } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { IpService } from '../shared/services/ip-service';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthenticationService } from '../idp/services/authentication-service.service';
import { BehaviorSubject, interval } from 'rxjs';
import { LoginResponse } from '../shared/models/idp/login';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styleUrl: './app.footer.component.scss'
})
export class AppFooterComponent {

    publicIp: string = '49.205.33.227';
    userGreetingMessage: string = 'Welcome!';
    items: MenuItem[];
    private currentUserSubject: BehaviorSubject<LoginResponse> = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem('currentUser')!));
    constructor(public layoutService: LayoutService,
        private ipService: IpService,
        private messageService: MessageService,
        private authenticationService: AuthenticationService) {
        this.getIPAddress();
        this.items = [
            {
                label: 'Company',
                icon: 'pi pi-fw pi-file',
                items: [
                    {
                        label: 'Organization Info',
                        icon: 'pi pi-fw pi-plus',
                    },
                    {
                        label: 'Copyright Notice',
                        icon: 'pi pi-fw pi-bookmark'
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Privacy Policy and Terms of Service',
                        icon: 'pi pi-fw pi-external-link'
                    }
                ]
            },
            {
                label: 'Navigation',
                icon: 'pi pi-fw pi-align-left',
                items: [
                    {
                        label: 'Quick Links',
                        icon: 'pi pi-fw pi-bookmark'
                    },
                    {
                        label: 'Site Map',
                        icon: 'pi pi-fw pi-user-plus'
                    }
                ]
            },
            {
                label: 'Localization',
                icon: 'pi pi-fw pi-pencil',
                items: [
                    {
                        label: 'Language Switcher',
                        icon: 'pi pi-fw pi-align-left'
                    },
                    {
                        label: 'Currency Selector',
                        icon: 'pi pi-fw pi-align-right'
                    }
                ]
            },
            {
                label: 'Legal and Compliance',
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'Terms and Conditions',
                        icon: 'pi pi-fw pi-user-plus'
                    },
                    {
                        label: 'Privacy Policy',
                        icon: 'pi pi-fw pi-user-minus'
                    },
                    {
                        label: 'Cookie Consent',
                        icon: 'pi pi-fw pi-users',
                    },
                    {
                        label: 'Analytics & Tracking',
                        icon: 'pi pi-fw pi-users',
                    },
                    {
                        label: 'Accessibility Statement',
                        icon: 'pi pi-fw pi-users',
                    }
                ]
            },
            {
                label: 'Social Media and Community',
                icon: 'pi pi-fw pi-calendar',
                items: [
                    {
                        label: 'Social Media Links',
                        icon: 'pi pi-fw pi-pencil'
                    },
                    {
                        label: 'Community Forums',
                        icon: 'pi pi-fw pi-calendar-times'
                    },
                    {
                        label: 'Blog or News',
                        icon: 'pi pi-fw pi-calendar-times'
                    }
                ]
            },
            {
                label: 'Customer Service and Support',
                icon: 'pi pi-fw pi-external-link',
                items: [
                    {
                        label: 'FAQ',
                        icon: 'pi pi-fw pi-pencil'
                    },
                    {
                        label: 'Live Chat or Support',
                        icon: 'pi pi-fw pi-calendar-times'
                    },
                    {
                        label: 'Help Center',
                        icon: 'pi pi-fw pi-calendar-times'
                    },
                    {
                        label: 'Feedback Form',
                        icon: 'pi pi-fw pi-calendar-times'
                    }
                ]
            },
            {
                label: 'Newsletter and Subscriptions',
                icon: 'pi pi-fw pi-calendar',
                items: [
                    {
                        label: 'Newsletter Sign-up',
                        icon: 'pi pi-fw pi-pencil'
                    },
                    {
                        label: 'Special Offers',
                        icon: 'pi pi-fw pi-calendar-times'
                    }
                ]
            },
            {
                label: 'Legal Disclaimers',
                icon: 'pi pi-fw pi-calendar',
                items: [
                    {
                        label: 'Disclaimers',
                        icon: 'pi pi-fw pi-pencil'
                    }
                ]
            }
        ];
    }

    ngOnInit(): void {
        const currentUser = this.currentUserSubject.value;
        const displayName = currentUser.applicationUser.displayName;
        this.userGreetingMessage ="Welcome "+ displayName +', '+  this.getGreeting();
    }
    getGreeting(): string {
        let date = new Date();
        const currentHour = date.getHours();

        if (currentHour < 12) {
            return 'Good morning!!';
        } else if (currentHour < 18) {
            return 'Good afternoon!!';
        } else {
            return 'Good evening!!';
        }
    }

    save(severity: string) {
        this.messageService.add({ severity: severity, summary: 'Success', detail: 'Data Saved' });
    }

    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    getIPAddress() {
        this.ipService.getPublicIp().subscribe({
            next: (response) => {
                this.publicIp = response.ip;
                console.log('IpService response:', response);
            },
            error: (error) => {
                console.error('Error fetching IP address', error);
            }
        });
    }
}
