export enum Roles {
    EXAMINATIONS = 'Examinations',
    ADMINISTRATION = 'Administration',
    ACADEMICS = 'Academics',
    ACADEMICSADMIN = 'AcademicsAdmin',
    SUPERADMIN = 'SuperAdmin',
    STUDENT = 'Student',
    LEADSADMIN = 'LeadsAdmin',
    ACCOUNTS = 'Accounts',
    ACCOUNTSADMIN = 'AccountsAdmin',
    MASTERS = 'Masters',
    EXAMINATIONSADMIN = 'ExaminationsAdmin',
    SUPERADMINISTRATOR = 'SuperAdministrator',
    MASTERSADMIN = 'MastersAdmin',
    MASTERSSUPERADMIN = 'MastersSuperAdmin',
    FACULTY = 'Faculty',
    LEADS = 'Leads',
    ITSUPPORTS = 'ITSupports',
    HR = 'Hr',
    HRAdmin = 'HRAdmin',
    HRSuperAdmin = 'HRSuperAdmin',
    HRReadWriteAccess = 'HRReadWriteAccess',
    ITSUPPORTSSUPERADMIN = 'ITSupportsSuperAdmin',
    DEVELOPERS = 'Developers',
    VIRTUALLEARNADMIN = 'VirtualLearnAdmin', 
    VIRTUALLEARNSUPERADMIN = 'VirtualLearnSuperAdmin',
    VIRTUALLEARN = 'VirtualLearn',
}