import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginResponse } from '../shared/models/idp/login';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    menu: MenuItem[] = [];

    @ViewChild('searchinput') searchInput!: ElementRef;

    @ViewChild('menubutton') menuButton!: ElementRef;

    searchActive: boolean = false;
    lastLoggedInUser: any;
    displayImageUrl: string = '';
    UserName: any;
    UserRole: any;
    
    private currentUserSubject: BehaviorSubject<LoginResponse> = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem('currentUser')!));

    constructor(public layoutService: LayoutService, private router: Router) { }

    ngOnInit(): void {
        const lastLoggedInUserStr = localStorage.getItem("lastLoggedInUser");
        const currentUser = this.currentUserSubject.value;

        if (currentUser && currentUser.applicationUser) {
            this.UserName = currentUser.applicationUser.displayName;
            const roles = currentUser.applicationUser.roles;
            this.UserRole = Array.isArray(roles) && roles.length > 0 ? roles[0] : 'No role assigned';
        }

        this.lastLoggedInUser = lastLoggedInUserStr ? JSON.parse(lastLoggedInUserStr) : { displayImageUrl: '', userName: '' };
        this.displayImageUrl = this.lastLoggedInUser.displayImageUrl?.length > 0
            ? this.lastLoggedInUser.displayImageUrl
            : 'assets/layout/images/avatar.png';
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    activateSearch() {
        this.searchActive = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    deactivateSearch() {
        this.searchActive = false;
    }

    removeTab(event: MouseEvent, item: MenuItem, index: number) {
        this.layoutService.onTabClose(item, index);
        event.preventDefault();
    }

    get layoutTheme(): string {
        return this.layoutService.config().layoutTheme;
    }

    get colorScheme(): string {
        return this.layoutService.config().colorScheme;
    }

    get logo(): string {
        const path = 'assets/layout/images/logo-';
        const logo = (this.layoutTheme === 'primaryColor' && !(this.layoutService.config().theme == "yellow")) ? 'light.png' : (this.colorScheme === 'light' ? 'dark.png' : 'light.png');
        return path + logo;
    }

    get tabs(): MenuItem[] {
        return this.layoutService.tabs;
    }
    logOut() {
        this.router.navigateByUrl('/logout');
    }

    Developers(){
        this.router.navigateByUrl('/home/developers/dashboard/dashboard');
    }
    onSettingsClick() {
        if (this.UserRole === "Student") {
            this.router.navigateByUrl('/home/students/student-profile-update');
        } else {
            this.router.navigateByUrl('/home/settings/applications/refresh-cached-data');
        }
    }
    onProfileClick() {
        if (this.UserRole === "Student") {
            this.router.navigateByUrl('/home/students/student-profile');
        }
    }
}