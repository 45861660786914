<div class="fluid formgrid grid view-container1 fadein animation-duration-500">
    <form [formGroup]="viewFormGroup" style="width: 100%;">
        <div class="w-full flex justify-content-end align-items-center gap-2">
            <!-- <label for="view" class="font-bold">View: </label> -->
             <span class="flex gap-1">
                <button pButton *ngFor="let option of viewOptions" type="button" (click)="onViewChange(option)"
                    [ngClass]="{'selected-view': viewFormGroup.value.view === option.value}" class="minimal-button" [pTooltip]="option.tooltip" 
                    tooltipPosition="top">
                    <i [class]="iconClass + ' pi ' + option.icon"></i>
                    <!-- {{ option.label }} -->
                </button>
             </span>
        </div>
        <hr>
    </form>

    @if(viewFormGroup.value.view === 'compact'){
        @if(!isLoading){
            @for (field of fields; track field){
                <div class="col-12 md:col-6 lg:col-2">
                    <div class="view-main1">
                        <div class="view-content1 comapct-view">
                            <label class="flex" style="gap:1rem;">
                                <i [class]="iconClass + ' pi ' + field?.icon"></i>
                                <label class="view-label1"><b>{{ field?.label }}</b>:</label>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    @if (field.type === 'image') {
                    <p-image [src]="data?.[field?.key || '']" alt="{{field?.label}}" width="250" [preview]="true"></p-image>
                    } @else if(field.type === 'innerHTML') {
                        <span class="view-value1" [style]="field.cssStyle" [innerHTML]="transformValue(data?.[field?.key || ''], field?.pipe)"></span>
                    } @else {
                    <label class="view-value1" [style]="field.cssStyle">{{ transformValue(data?.[field?.key || ''], field?.pipe)
                        }}</label>
                    }
                </div>
            }
        } @else {
            <ng-container *ngFor="let skeleton of [].constructor(8); let i = index">
                <div class="col-12 md:col-6 lg:col-2">
                    <div class="view-main1">
                        <div class="view-content1 compact-view">
                            <label class="flex" style="gap:1rem;">
                                <p-skeleton width="2rem" height="2rem" shape="circle"></p-skeleton>
                                <p-skeleton width="6rem" height="1.5rem"></p-skeleton>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <p-skeleton width="100%" height="2rem"></p-skeleton>
                </div>
            </ng-container>
        }
    } @else if (viewFormGroup.value.view === 'tiles') {
    <div class="fluid formgrid grid view-container">
        @if(!isLoading){
            @for (field of fields; track field){
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="view-main">
                        <div class="view-content">
                            <label class="flex" style="gap:1rem;">
                                <i [class]="iconClass + ' pi ' + field?.icon"></i>
                                <label class="view-label">{{ field?.label }}:</label>
                            </label>
                            @if (field.type === 'image') {
                            <p-image [src]="data?.[field?.key || '']" alt="{{field?.label}}" width="75"></p-image>
                            } @else if(field.type === 'innerHTML') {
                                <span class="view-value1" [style]="field.cssStyle" [innerHTML]="transformValue(data?.[field?.key || ''], field?.pipe)"></span>
                            } @else {
                            <label class="view-value" [style]="field.cssStyle">{{ transformValue(data?.[field?.key || ''],
                                field?.pipe) }}</label>
                            }
                        </div>
                    </div>
                </div>
            }
               
            } @else {
                <ng-container *ngFor="let skeleton of [].constructor(8); let i = index">
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="view-main">
                            <div class="view-content">
                                <label class="flex" style="gap:1rem;">
                                    <p-skeleton width="2rem" height="2rem" shape="circle"></p-skeleton>
                                    <p-skeleton width="6rem" height="1.5rem"></p-skeleton>
                                </label>
                                <p-skeleton width="75%" height="2rem"></p-skeleton>
                            </div>
                        </div>
                    </div>
                </ng-container>
            }
    </div>
    }
    @else if (viewFormGroup.value.view === 'list') {
        @if(!isLoading){
            <div class="fluid list-view w-full px-3">
                <ul>
                    @for (field of fields; track field) {
                            <li class="list-item">
                                <label class="list-label">
                                    <i [class]="iconClass + ' pi ' + field?.icon"></i>
                                    {{ field?.label }}:
                                </label>
                                @if (field.type === 'image') {
                                <p-image [src]="data?.[field?.key || '']" alt="{{field?.label}}" width="75" [preview]="true"></p-image>
                                } @else if(field.type === 'innerHTML') {
                                    <span class="view-value1" [style]="field.cssStyle" [innerHTML]="transformValue(data?.[field?.key || ''], field?.pipe)"></span>
                                } @else {
                                <span class="list-value" [style]="field.cssStyle">{{ transformValue(data?.[field?.key || ''],
                                    field?.pipe) }}</span>
                                }
                            </li>
                    }
                </ul>
            </div>
        }   @else {
                <div class="fluid list-view w-full px-3">
                    <ul>
                        <ng-container *ngFor="let skeleton of [].constructor(8); let i = index">
                            <li class="list-item">
                                <label class="list-label flex gap-1">
                                    <p-skeleton width="2rem" height="2rem" shape="circle"></p-skeleton>
                                    <p-skeleton width="8rem" height="1.5rem"></p-skeleton>
                                </label>
                                <span class="list-value"><p-skeleton width="8rem" height="2rem"></p-skeleton></span>
                                
                            </li>
                        </ng-container>
                    </ul>
                </div>
            }
        }
</div>