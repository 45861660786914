import { NgModule, isDevMode } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule, EffectsRootModule } from '@ngrx/effects';
import { SharedModule } from './shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ProgressBarInterceptor } from './shared/interceptors/progress-bar.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';

@NgModule({
    imports: [
        HttpClientModule,
        SharedModule,
        AppLayoutModule,
        AppRoutingModule,
        EffectsModule.forRoot([]),
        StoreModule.forRoot([]),
        StoreDevtoolsModule.instrument({ logOnly: isDevMode() }),
    ],
    declarations: [
        AppComponent,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
