import { ChangeDetectorRef, Component } from '@angular/core';
import { SharedModule } from 'src/app/shared.module';
import { ProgressBarService } from '../../services/common/progress-bar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
  showProgress = false;
  constructor(private progressBarService: ProgressBarService, private cdRef: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.init();
  }
  init() {
    this.progressBarService.getProgressBarObserver().subscribe((status) => {
      const currentRoute = this.router.url;
      this.showProgress = (status === 'start') &&
        (currentRoute !== '/Login') &&
        (currentRoute !== '/') &&
        (currentRoute !== '/Home/Landing') &&
        (currentRoute !== '/Home/Settings/RefreshCachedData');
      this.cdRef.detectChanges();
    });
  }
}