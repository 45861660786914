import { Component } from '@angular/core';

@Component({
  selector: 'app-under-progress',
  standalone: true,
  imports: [],
  templateUrl: './under-progress.component.html',
  styleUrl: './under-progress.component.scss'
})
export class UnderProgressComponent {

}
