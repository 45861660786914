<div class="card footer">
    <div class="col-2">
    </div>
    <div class="col-3">
        <b>{{userGreetingMessage}}</b>
    </div>
    <div class="col-3">
        <b> © Copyright 2024 - PyxisBlu. All rights reserved.</b>
    </div>
    <div class="col-3">
        <b>IP Address:</b> {{publicIp}}
    </div>
    <div class="col-2">
        <p-splitButton 
            label="Quick Links" 
            (onClick)="save('info')" 
            [model]="items" />
    </div>
</div>