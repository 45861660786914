import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { LoginResponse } from 'src/app/shared/models/idp/login';
import { LocalstorageService } from 'src/app/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private loadingState = new BehaviorSubject<boolean>(false);
  isLoading$ = this.loadingState.asObservable();
  currentUserSubject: any;

  constructor(private http: HttpClient, private localstorageService: LocalstorageService) { }

  private handleApiCall<T>(apiCall$: Observable<T>, retryCount: number = 3): Observable<T> {
    return apiCall$.pipe(
      retry(retryCount),
      catchError(error => {
        console.error('API call failed', error);
        return of(null as T);
      })
    );
  }

  loadApiData(currentUserSubject: any): void {
    this.setLoadingState(true);
    this.currentUserSubject = currentUserSubject;
    const globalApiData$ = this.getGlobalApiData();
    const additionalApiCalls = [];

    const roles = this.currentUserSubject.value.applicationUser.roles;
    if (roles.some((role: any) => role.trim().includes('Masters') || role.trim().includes('Administration') || role.trim().includes('SuperAdministration'))) {
      additionalApiCalls.push(this.getCloudBytesApiData());
    }
    if (roles.some((role: any) => role.trim().includes('Academics') || role.trim().includes('Administration') || role.trim().includes('SuperAdministration'))) {
      additionalApiCalls.push(this.getMindSparkApiData());
    }
    if (roles.some((role: any) => role.trim().includes('Examinations') || role.trim().includes('Administration') || role.trim().includes('SuperAdministration'))) {
      additionalApiCalls.push(this.getKnowledgeStandApiData());
    }

    forkJoin([globalApiData$, ...additionalApiCalls]).subscribe({
      next: ([globalData, ...additionalData]) => {
        this.storeGlobalData(globalData);

        if (roles.some((role: any) => role.trim().includes('Masters'))) {
          this.storeCloudBytesData(additionalData.shift());
        }
        if (roles.some((role: any) => role.trim().includes('Academics') || role.trim().includes('Administration') || role.trim().includes('SuperAdministration'))) {
          this.storeMindSparkData(additionalData.shift());
        }
        if (roles.some((role: any) => role.trim().includes('Examinations') || role.trim().includes('Administration') || role.trim().includes('SuperAdministration'))) {
          this.storeKnowledgeStandData(additionalData.shift());
        }

        this.setLoadingState(false);
      },
      error: error => {
        console.error('Error loading data:', error);
        this.setLoadingState(false);
      }
    });
  }

  storeGlobalData(data: any): void {
    try {
      this.localstorageService.setItem("AcademicSessionList", data.academicSessionApiResponse);
      this.localstorageService.setItem("AcademicSessionProgramList", data.academicSessionProgramApiResponse);
      this.localstorageService.setItem("ProgramList", data.programApiResponse);
      this.localstorageService.setItem("OperationalVerticalList", data.operationalVerticalApiResponse);
      this.localstorageService.setItem("DegreeTypeList", data.degreeTypeApiResponse);
      this.localstorageService.setItem("DegreeList", data.degreeApiResponse);
      this.localstorageService.setItem("FacultyList", data.facultyApiResponse);
      this.localstorageService.setItem("FacultyDepartmentList", data.facultyDepartmentApiResponse);
      this.localstorageService.setItem("ProgramSpecializationList", data.programSpecializationApiResponse);
      this.localstorageService.setItem("SubjectList", data.subjectApiResponse);
      this.localstorageService.setItem("PaperTypeList", data.paperTypeApiResponse);
      this.localstorageService.setItem("SubjectTypeList", data.subjectTypeApiResponse);
      this.localstorageService.setItem("FeeComponentList", data.feeComponentApiResponse);
      this.localstorageService.setItem("ConcessionCategoryList", data.concessionCategoryApiResponse);
      this.localstorageService.setItem("SubjectPaperCodeList", data.subjectPaperCodeApiResponse);
      this.localstorageService.setItem("ReligionList", data.religionApiResponse);
      this.localstorageService.setItem("CasteList", data.casteApiResponse);
      this.localstorageService.setItem("DepartmentList", data.departmentApiResponse);
      this.localstorageService.setItem("DesignationList", data.designationApiResponse);
      this.localstorageService.setItem("IdentityTypeList", data.identityTypeApiResponse);
      this.localstorageService.setItem("BuildingList", data.buildingsApiResponse);
      this.localstorageService.setItem("RoomList", data.roomApiResponse);
    } catch (error) {
      console.error('LocalStorage limit exceeded:', error);
      this.setLoadingState(false);
      throw new Error('LocalStorage limit exceeded, stopping data loading.');
    }
  }

  storeCloudBytesData(data: any): void {
    try {
      this.localstorageService.setItem("PartnerList", data.partnerResponse);
      this.localstorageService.setItem("PartnerImageTypeList", data.partnerImageTypeResponse);
      this.localstorageService.setItem("PartnerImageList", data.partnerImageResponse);
      this.localstorageService.setItem("ServiceRequestDepartmentList", data.ServiceRequestDepartmentResponse);
      this.localstorageService.setItem("ServiceRequestWorkgroupList", data.ServiceRequestWorkgroupResponse);
      this.localstorageService.setItem("ServiceRequestCategoryList", data.ServiceRequestCategoryResponse);
      this.localstorageService.setItem("ServiceRequestSubCategoryList", data.ServiceRequestSubCategoryResponse);
      this.localstorageService.setItem("ServiceRequestMappingList", data.ServiceRequestMappingResponse);
      this.localstorageService.setItem("StudentStatusDescriptionList", data.StudentStatusDescriptionResponse);
      this.localstorageService.setItem("AcademicHolidayList", data.AcademicHolidayResponse);
    } catch (error) {
      console.error('LocalStorage limit exceeded:', error);
      this.setLoadingState(false);
      throw new Error('LocalStorage limit exceeded, stopping data loading.');
    }
  }

  storeExecutiveEdgeData(data: any): void {
    try {
      this.localstorageService.setItem("FeedbackQuestionList", data.feedbackQuestionApiResponse);
      this.localstorageService.setItem("NoticeList", data.noticeApiResponse);
      this.localstorageService.setItem("StudentNoticeList", data.studentNoticeApiResponse);
      this.localstorageService.setItem("DocumentCenterList", data.documentCenterApiResponse);
      this.localstorageService.setItem("FeedbackAnnouncementList", data.feedbackAnnouncementApiResponse);
    } catch (error) {
      console.error('LocalStorage limit exceeded:', error);
      this.setLoadingState(false);
      throw new Error('LocalStorage limit exceeded, stopping data loading.');
    }
  }

  storeMindSparkData(data: any): void {
    try {
      this.localstorageService.setItem("BatchScheduleMasterList", data.batchScheduleMasterApiResponse);
      this.localstorageService.setItem("FacultySubjectAllocationList", data.facultySubjectAllocationApiResponse);

    } catch (error) {
      console.error('LocalStorage limit exceeded:', error);
      this.setLoadingState(false);
      throw new Error('LocalStorage limit exceeded, stopping data loading.');
    }
  }

  storeKnowledgeStandData(data: any): void {
    try {
      this.localstorageService.setItem("ExaminationList", data.examinationApiResponse);
      this.localstorageService.setItem("ExaminationTypeList", data.examinationTypeApiResponse);
      this.localstorageService.setItem("AssessmentTypeList", data.assessmentTypeApiResponse);
      this.localstorageService.setItem("AssessmentComponentList", data.assessmentComponentApiResponse);
      this.localstorageService.setItem("ActiveExaminationResponseList", data.activeExaminationApiResponse);
    } catch (error) {
      console.error('LocalStorage limit exceeded:', error);
      this.setLoadingState(false);
      throw new Error('LocalStorage limit exceeded, stopping data loading.');
    }
  }

  getGlobalApiData(): Observable<any> {
    const apiCalls = [
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/AcademicSession/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Program/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/OperationalVertical/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/AcademicSessionProgram/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/DegreeType/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Degree/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Faculty/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/FacultyDepartment/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/ProgramSpecialization/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Subject/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/PaperType/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/SubjectType/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/FeeComponent/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/ConcessionCategory/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/SubjectPaperCode/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Religion/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Caste/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Department/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Designation/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/IdentityType/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Building/GetAll')),
      this.handleApiCall(this.http.get(environment.apiGlobalUrl + '/Room/GetAll'))
    ];

    return forkJoin(apiCalls).pipe(
      map(responses => {
        const [
          academicSessionApiResponse, programApiResponse, operationalVerticalApiResponse, academicSessionProgramApiResponse,
          degreeTypeApiResponse, degreeApiResponse, facultyApiResponse, facultyDepartmentApiResponse, programSpecializationApiResponse,
          subjectApiResponse, paperTypeApiResponse, subjectTypeApiResponse, feeComponentApiResponse, concessionCategoryApiResponse,
          subjectPaperCodeApiResponse, religionApiResponse, casteApiResponse, departmentApiResponse, designationApiResponse,
          identityTypeApiResponse, buildingsApiResponse, roomApiResponse
        ] = responses;
        return {
          academicSessionApiResponse, programApiResponse, operationalVerticalApiResponse, academicSessionProgramApiResponse,
          degreeTypeApiResponse, degreeApiResponse, facultyApiResponse, facultyDepartmentApiResponse, programSpecializationApiResponse,
          subjectApiResponse, paperTypeApiResponse, subjectTypeApiResponse, feeComponentApiResponse, concessionCategoryApiResponse,
          subjectPaperCodeApiResponse, religionApiResponse, casteApiResponse, departmentApiResponse, designationApiResponse,
          identityTypeApiResponse, buildingsApiResponse, roomApiResponse
        };
      }),
      catchError(error => {
        console.error('Some API calls failed:', error);
        return of({ error: 'Some API calls failed', responses: error });
      })
    );
  }

  getCloudBytesApiData(): Observable<any> {
    const data = localStorage.getItem('currentUser');
    if (data) {
      this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(data));
      const partnerCode = this.currentUserSubject.value.applicationUser.partnerCode;

      const apiCalls = [
        this.handleApiCall(this.http.get(`${environment.apiMastersUrl}/Partner/GetByPartnerCode/${partnerCode}`)),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/PartnerImageType/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/PartnerImage/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/ServiceRequestDepartment/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/ServiceRequestWorkgroup/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/ServiceRequestCategory/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/ServiceRequestSubCategory/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/ServiceRequestMapping/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/StudentStatusDescription/GetAll')),
        this.handleApiCall(this.http.get(environment.apiMastersUrl + '/AcademicHoliday/GetAll'))
      ];

      return forkJoin(apiCalls).pipe(
        map(responses => {
          const [
            partnerResponse, partnerImageTypeResponse, partnerImageResponse, serviceRequestDepartmentResponse, serviceRequestWorkgroupResponse,
            serviceRequestCategoryResponse, serviceRequestSubCategoryResponse, serviceRequestMappingResponse, studentStatusDescriptionResponse,
            academicHolidayResponse
          ] = responses;
          return {
            partnerResponse, partnerImageTypeResponse, partnerImageResponse, serviceRequestDepartmentResponse, serviceRequestWorkgroupResponse,
            serviceRequestCategoryResponse, serviceRequestSubCategoryResponse, serviceRequestMappingResponse, studentStatusDescriptionResponse,
            academicHolidayResponse
          };
        }),
        catchError(error => {
          console.error('Some API calls failed:', error);
          return of({ error: 'Some API calls failed', responses: error });
        })
      );
    } else {
      return of(null);
    }
  }

  getExecutiveEdgeApiData(): Observable<any> {
    const apiCalls = [
      this.handleApiCall(this.http.get(environment.apiExecutiveEdgeUrl + '/FeedbackQuestion/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExecutiveEdgeUrl + '/Notice/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExecutiveEdgeUrl + '/StudentNotice/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExecutiveEdgeUrl + '/DocumentCenter/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExecutiveEdgeUrl + '/FeedbackAnnouncement/GetAll'))
    ];

    return forkJoin(apiCalls).pipe(
      map(responses => {
        const [
          feedbackQuestionApiResponse, noticeApiResponse, studentNoticeApiResponse, documentCenterApiResponse, feedbackAnnouncementApiResponse
        ] = responses;
        return {
          feedbackQuestionApiResponse, noticeApiResponse, studentNoticeApiResponse, documentCenterApiResponse, feedbackAnnouncementApiResponse
        };
      }),
      catchError(error => {
        console.error('Some API calls failed:', error);
        return of({ error: 'Some API calls failed', responses: error });
      })
    );
  }

  getMindSparkApiData(): Observable<any> {
    const apiCalls = [
      this.handleApiCall(this.http.get(environment.apiAcademicsUrl + '/BatchScheduleMaster/GetAll')),
      this.handleApiCall(this.http.get(environment.apiAcademicsUrl + '/FacultySubjectAllocation/GetAll'))
    ];

    return forkJoin(apiCalls).pipe(
      map(responses => {
        const [
          batchScheduleMasterApiResponse, facultySubjectAllocationApiResponse
        ] = responses;
        return {
          batchScheduleMasterApiResponse, facultySubjectAllocationApiResponse
        };
      }),
      catchError(error => {
        console.error('Some API calls failed:', error);
        return of({ error: 'Some API calls failed', responses: error });
      })
    );
  }

  getKnowledgeStandApiData(): Observable<any> {
    const apiCalls = [
      this.handleApiCall(this.http.get(environment.apiExaminationsUrl + '/Examination/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExaminationsUrl + '/ExaminationType/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExaminationsUrl + '/AssessmentType/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExaminationsUrl + '/AssessmentComponent/GetAll')),
      this.handleApiCall(this.http.get(environment.apiExaminationsUrl + '/Examination/GetActiveExaminations'))
    ];

    return forkJoin(apiCalls).pipe(
      map(responses => {
        const [
          examinationApiResponse, examinationTypeApiResponse, assessmentTypeApiResponse, assessmentComponentApiResponse, activeExaminationApiResponse
        ] = responses;
        return {
          examinationApiResponse, examinationTypeApiResponse, assessmentTypeApiResponse, assessmentComponentApiResponse, activeExaminationApiResponse
        };
      }),
      catchError(error => {
        console.error('Some API calls failed:', error);
        return of({ error: 'Some API calls failed', responses: error });
      })
    );
  }

  setLoadingState(isLoading: boolean) {
    this.loadingState.next(isLoading);
  }
}