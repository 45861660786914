<nav class="layout-breadcrumb" style="margin-left: 1rem;">
    <ol>
        <li>
            <a [routerLink]="['/home/dashboard']">
                <!-- <i class="pi pi-home"></i> -->
                <span class="app-name" style="font-weight: bold;">NCorePro.UI.Client</span>
            </a>
        </li>
        <!-- <li>{{moduleName}}</li> -->
        <li class="layout-breadcrumb-chevron"> / </li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li>{{item.label}}</li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron"> / </li>
        </ng-template>
    </ol>
</nav>