import partnerBasicInfo from '../assets/jsonFiles/partnerBasicInfo.json';

export const environment = {
  production: true,
  identityServerUrl: "https://api-dev-idp.ncorepro.com",
  apiMastersUrl: "https://api-dev-cloudbytes.ncorepro.com",
  apiAcademicsUrl: "https://api-dev-mindspark.ncorepro.com",
  apiExaminationsUrl: "https://api-dev-knowledgestand.ncorepro.com",
  apiAccountsUrl: "https://api-dev-finpro.ncorepro.com",
  apiLeadsUrl: "https://api-dev-bigleads.ncorepro.com",
  apiStudentsUrl: "https://api-dev-students.ncorepro.com",
  apiHumanResourcesUrl: "https://api-dev-smallbizgurus.ncorepro.com",
  apiTimeClockPlusUrl: "https://api-dev-timeclockplus.ncorepro.com",
  apiDigitalFingersUrl: "https://api-dev-digitalfingers.ncorepro.com",
  apiExecutiveEdgeUrl: "https://api-dev-executiveedge.ncorepro.com",
  apiSignalRUrl: "https://api-dev-signalr.ncorepro.com",
  apiFilemanagerUrl: "https://api-dev-filemanager.ncorepro.com",
  apiVirtualLearnUrl: "https://api-dev-virtuallearn.ncorepro.com",
  apiGlobalUrl: "https://api-dev-global.ncorepro.com",
};