<div class="layout-topbar">
    <a class="app-logo" routerLink="/">
        <img alt="app logo" [src]="logo">
        <span class="app-name">NCorePro.UI.Client</span>
    </a>
    
    <div style="font-size: large;">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
        <span></span>
    </button>

    <ul class="topbar-menu">
        <li *ngFor="let item of tabs; let i = index">
            <a [routerLink]="item.routerLink" routerLinkActive="active-route" [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
                [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment!" 
                [skipLocationChange]="item.skipLocationChange!" [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
                <span>{{item.label}}</span>
            </a>
            <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
        </li>
        <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty">
            
        </li>
    </ul>

  

    <div class="topbar-search" [ngClass]="{'topbar-search-active': searchActive}">
        <button class="topbar-searchbutton p-link" type="button" (click)="activateSearch()">
            <i class="pi pi-search"></i>
        </button>

        <div class="search-input-wrapper">
            <span class="p-input-icon-right">
                <input #searchinput type="text" pInputText placeholder="Search" (blur)="deactivateSearch()" (keydown.escape)="deactivateSearch()"/>
                <i class="pi pi-search"></i>
            </span>
        </div>
    </div>
    
    <div class="topbar-profile">
        <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterFromClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
            <img alt="avatar" [src]="displayImageUrl" style="border-radius: 50%;" height="36px">
            <span class="profile-details">
                <span class="profile-name">{{UserName}}</span>
                <span class="profile-job">{{UserRole}}</span>
            </span>
            <i class="pi pi-angle-down"></i>
        </button>
        <ul class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
            <li>
                <a pRipple (click)="onProfileClick()" class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="fas fa-user mr-3"></i>
                    <span>Profile</span>
                </a>
                <span [hidden]="UserRole === 'Student' ? true : false">
                    <a (click)="Developers()" pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                        <i class="fas fa-laptop-code mr-3"></i>
                        <span>Developers</span>
                    </a>
                </span>
                <a pRipple (click)="onSettingsClick()" class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="fas fa-gear mr-3"></i>
                    <span>Settings</span>
                </a>
                <a (click)="logOut()" pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                    <i class="fas fa-power-off mr-3"></i>
                    <span>Log Out</span>
                </a>
            </li>
        </ul>
    </div>
</div>
