import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-generic-table',
  standalone: true,
  imports: [TableModule, ButtonModule,TooltipModule,CommonModule,InputTextModule,SkeletonModule, ImageModule],
  templateUrl: './generic-table.component.html',
  styleUrl: './generic-table.component.scss'
})
export class GenericTableComponent implements OnInit {
  @Input() value: any[] = [];
  @Input() cols: any[] = [];
  @Input() rows: number = 10;
  @Input() paginator!: boolean;
  @Input() rowsPerPageOptions: any[] = [10, 20, 30, 40, 50, 100, { showAll: 'All' }];
  @Input() selectionMode: any;
  @Input() selection: any;
  @Output() selectionChange = new EventEmitter<any>();
  @Input() globalFilterFields: string[] = [];
  @Input() dataKey: string = 'id';
  @Input() autoLayout!: boolean;
  @Input() resizableColumns!: boolean;
  @Input() styleClass: string = 'p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm';
  @Input() currentPageReportTemplate: string = 'Showing {first} to {last} of {totalRecords} entries';
  @Input() rowStyle: (row: any) => any = () => ({});
  @Input() entries: string = '';
  @Input() showCurrentPageReport: any;
  @Input() actions: any[] = [];
  @Output() onAction = new EventEmitter<{ action: string, data: any }>();
  @ViewChild('searchInput', { static: false }) searchInput: any;
  @Input() isLoading: boolean = true;
  skeletonValue: number[] = Array(4).fill(1);

  constructor() { }

  ngOnInit(): void {
  }
  
  clear(table: Table) {
    table.clear();
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
  }
  
  triggerAction(action: string, data: any) {
    this.onAction.emit({ action, data });
  }

  onSelectionChange(newSelection: any) {
    this.selection = newSelection;
    this.selectionChange.emit(this.selection);
  }

  getButtonClass(action: any): string {
    if (action.name === 'view') {
      return 'p-button-primary';
    }
    if (action.name === 'delete') {
      return 'p-button-danger';
    }
    return '';
  }

  getColumnClass(col: any): string {
    return col.class || 'text-left';
  }
}